/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    pre: "pre",
    a: "a",
    blockquote: "blockquote",
    ul: "ul",
    li: "li",
    em: "em",
    br: "br"
  }, _provideComponents(), props.components), {Aside} = _components;
  if (!Aside) _missingMdxReference("Aside", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "To declare a variable in Rust, use the ", React.createElement(_components.code, null, "let"), " keyword."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let num = 5;\n")), "\n", React.createElement(_components.p, null, "By default, variables are immutable, you can’t change them.\nIf you wish to mutate it, you have to explicitly state that by adding ", React.createElement(_components.code, null, "mut"), " to that declaration."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "let mut num = 5;\n")), "\n", React.createElement(_components.p, null, "Now, the number stored in the variable named ", React.createElement(_components.code, null, "num"), " may now be changed."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "num = 6;\n")), "\n", React.createElement(Aside, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/laurieontech"
  }, "Laurie Barth"), " has ", React.createElement(_components.a, {
    href: "https://laurieontech.com/posts/rust-mut/"
  }, "an excellent post on ", React.createElement(_components.code, null, "mut")))), "\n", React.createElement(_components.p, null, "Another piece of data that’s immutable, (so can’t change) is a constant.\nDeclared with the ", React.createElement(_components.code, null, "const"), " keyword."), "\n", React.createElement(_components.p, null, "You might already be familiar with this concept, and wonder:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "If variables are immutable by default, what’s the difference between variables and constants?"), "\n", React.createElement("footer", null, "you"), "\n"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "You aren’t allowed to use ", React.createElement(_components.code, null, "mut"), " on constants. No mutating, ever."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "The type of a constant ", React.createElement(_components.em, null, "must"), " be declared, whereas the type of a variable ", React.createElement(_components.em, null, "may"), " be declared."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Constants can only be set to a constant ", React.createElement(_components.a, {
    href: "/garden/rust-expression-statement"
  }, "expression"), ", not to the result of a function call or anything that could only be determined at runtime."), "\n"), "\n"), "\n", React.createElement(_components.p, null, "As a result, constants are always fixed in size, and known at compile time.", React.createElement(_components.br), "\n", "Another, less obvious result, is that constants may not be of a type that requires ", React.createElement(_components.a, {
    href: "/garden/rust-stack-heap"
  }, "allocation on the heap"), ", since they’re not known at compile time."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The naming convention for ", React.createElement(_components.code, null, "const"), " is ", React.createElement(_components.code, null, "SCREAMING_SNAKE_CASE"), "."), "\n", React.createElement(_components.li, null, "The naming convention for ", React.createElement(_components.code, null, "let"), " is ", React.createElement(_components.code, null, "snake_case"), "."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "const SPEED_OF_LIGHT: u32 = 299792458;\n")), "\n", React.createElement(_components.p, null, "Constants can be declared in any scope, including the global scope.", React.createElement(_components.br), "\n", "They are valid for the entire runtime of the program inside that scope.", React.createElement(_components.br), "\n", "Translation: You can put constants outside of the ", React.createElement(_components.code, null, "main"), " function and it will work."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
